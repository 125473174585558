import React from "react"
import cx from "classnames"
import { Link } from "gatsby"

import Payment from "~/models/payment"

type Props = {
  payment: Payment
  className?: string
}

function PaymentCard({ payment, className }: Props) {
  return (
    <Link
      to={payment.page?.url.pathname || ""}
      className={cx(
        "block py-6 lg:py-10 border-b border-b-purple-100",
        className
      )}
    >
      <div className="flex items-center mb-4 lg:mb-6">
        <div className="rounded-full overflow-hidden mr-6">
          <img
            src={`/images/payment-providers/${payment.slug}.svg`}
            alt={payment.imageDescription}
            className="block w-16 h-16"
            decoding="async"
          />
        </div>

        <span className="text-lg lg:text-5xl leading-none text-font-1 font-bold">
          {payment.name}
        </span>
      </div>

      <p className="text-lg text-font-2">{payment.description}</p>
    </Link>
  )
}

export default PaymentCard
