import React from "react"

import Payment from "~/models/payment"

import PaymentCard from "~/ui/components/payment/card"

type Props = {
  payments: Payment[]
}

function PaymentList({ payments }: Props) {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-10 xl:px-20 py-12 md:py-20">
      {payments.map((payment, index) => {
        return <PaymentCard key={index} payment={payment} />
      })}
    </div>
  )
}

export default PaymentList
