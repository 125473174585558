import React from "react"
import { graphql } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Payment from "~/models/payment"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import ChecklistBlock from "~/ui/compositions/generic/checklist"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import ParagraphsBlock from "../compositions/generic/paragraphs"
import PaymentListBlock from "../compositions/payment/list"
import StepsBlock from "../compositions/generic/steps"
import CardGridBlock from "~/ui/compositions/generic/card-grid"

import {
  PaymentListQuery,
  ContentfulPage,
  ContentfulPayment,
} from "../../../types/graphql-types"

type Props = {
  data: PaymentListQuery
}

function PaymentListPage({ data }: Props) {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const payments = data.allContentfulPayment.nodes.map(payment => {
    return new Payment(payment as ContentfulPayment)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />
        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && 
              <React.Fragment>
                <PageCoverBlock {...block.props} />
                <div className="mb-2 mt-2">
                  <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                    <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                  </div>
                </div>
              </React.Fragment>
            }
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
            {block.name == "Payments" && (
              <PaymentListBlock payments={payments} {...block.props} />
            )}
            {block.name == "Steps" && <StepsBlock {...block.props} />}
            {block.name == "CardGrid" && <CardGridBlock {...block.props} />}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default PaymentListPage

export const query = graphql`
  query PaymentList {
    contentfulPage(url: { pathname: { eq: "/payments/" } }) {
      ...PageFields
    }
    allContentfulPayment {
      nodes {
        ...PaymentFields
      }
    }
  }
`
