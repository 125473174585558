import React from "react"

type Props = {
  className?: string
  width?: number
  number: number
}

function Step(props: Props) {
  return (
    <svg
      width={props.width}
      className={props.className}
      viewBox="0 0 174 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text x="141" y="36" fill="#5E6EFF" fontSize="24" fontWeight="bold">
        {props.number}
      </text>

      <path
        d="M44.748 37.288C43.516 37.288 42.436 37.072 41.508 36.64C40.58 36.208 39.852 35.592 39.324 34.792C38.812 33.992 38.548 33.04 38.532 31.936H41.22C41.236 32.832 41.548 33.592 42.156 34.216C42.764 34.84 43.62 35.152 44.724 35.152C45.7 35.152 46.46 34.92 47.004 34.456C47.564 33.976 47.844 33.368 47.844 32.632C47.844 32.04 47.708 31.56 47.436 31.192C47.18 30.824 46.82 30.52 46.356 30.28C45.908 30.04 45.388 29.824 44.796 29.632C44.204 29.44 43.58 29.232 42.924 29.008C41.628 28.576 40.652 28.016 39.996 27.328C39.356 26.64 39.036 25.736 39.036 24.616C39.02 23.672 39.236 22.848 39.684 22.144C40.148 21.44 40.788 20.896 41.604 20.512C42.436 20.112 43.404 19.912 44.508 19.912C45.596 19.912 46.548 20.112 47.364 20.512C48.196 20.912 48.844 21.472 49.308 22.192C49.772 22.896 50.012 23.72 50.028 24.664H47.34C47.34 24.232 47.228 23.824 47.004 23.44C46.78 23.04 46.452 22.712 46.02 22.456C45.588 22.2 45.06 22.072 44.436 22.072C43.636 22.056 42.972 22.256 42.444 22.672C41.932 23.088 41.676 23.664 41.676 24.4C41.676 25.056 41.868 25.56 42.252 25.912C42.636 26.264 43.164 26.56 43.836 26.8C44.508 27.024 45.276 27.288 46.14 27.592C46.972 27.864 47.716 28.192 48.372 28.576C49.028 28.96 49.548 29.464 49.932 30.088C50.332 30.712 50.532 31.504 50.532 32.464C50.532 33.312 50.316 34.104 49.884 34.84C49.452 35.56 48.804 36.152 47.94 36.616C47.076 37.064 46.012 37.288 44.748 37.288Z"
        fill="#5E6EFF"
      />
      <path
        d="M56.9447 37V22.264H51.9767V20.2H64.4327V22.264H59.4887V37H56.9447Z"
        fill="#5E6EFF"
      />
      <path
        d="M66.8134 37V20.2H77.4934V22.264H69.3574V27.496H76.7734V29.512H69.3574V34.936H77.4934V37H66.8134Z"
        fill="#5E6EFF"
      />
      <path
        d="M80.5243 37V20.2H86.4043C87.7163 20.2 88.8043 20.424 89.6683 20.872C90.5323 21.304 91.1723 21.904 91.5883 22.672C92.0203 23.424 92.2363 24.28 92.2363 25.24C92.2363 26.152 92.0283 26.992 91.6123 27.76C91.2123 28.512 90.5803 29.112 89.7163 29.56C88.8523 30.008 87.7483 30.232 86.4043 30.232H83.0683V37H80.5243ZM83.0683 28.144H86.2843C87.4843 28.144 88.3403 27.888 88.8523 27.376C89.3803 26.848 89.6443 26.136 89.6443 25.24C89.6443 24.312 89.3803 23.592 88.8523 23.08C88.3403 22.552 87.4843 22.288 86.2843 22.288H83.0683V28.144Z"
        fill="#5E6EFF"
      />
      <path
        d="M103.187 1H29C13.5361 1 0.999997 13.536 0.999997 29V29C0.999997 44.464 13.536 57 29 57H146"
        stroke="#350025"
      />
      <rect
        x="118.5"
        y="1.5"
        width="55"
        height="55"
        rx="27.5"
        stroke="#350025"
      />
    </svg>
  )
}

export default Step
