import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import StepIcon from "~/ui/identity/icon/step"
import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description?: string
  items: StepProps[]
  paragraphs: string[]
  grid: number
}

Steps.defaultProps = {
  paragraphs: [],
  grid: 3,
}

function Steps({ title, description, items, paragraphs, grid }: Props) {
  return (
    <TitledSection title={title} description={description}>
      <div
        className={cx(
          "grid grid-cols-1 gap-y-20 gap-x-4 md:gap-y-28 md:gap-x-20 pb-[72px]",
          {
            "md:grid-cols-3": grid == 3,
            "md:grid-cols-4": grid == 4,
          }
        )}
      >
        {items.map((item, index) => {
          return (
            <Step
              key={index}
              image={item.image}
              body={item.body}
              index={index}
            />
          )
        })}
      </div>

      <React.Fragment>
        {paragraphs && (
          <div className="lg:max-w-[738px] mx-auto mt-6 lg:mt-14">
            {paragraphs.map((paragraph, index) => {
              return (
                <p
                  key={index}
                  className="text-lg text-font-2 text-center"
                  dangerouslySetInnerHTML={sanitize(paragraph)}
                ></p>
              )
            })}
          </div>
        )}
      </React.Fragment>
    </TitledSection>
  )
}

type StepProps = {
  image: string
  body: string
  index: number
  className?: string
}

function Step({ image, body, className, index }: StepProps) {
  return (
    <div
      className={cx(
        "relative bg-[#F7F8FF] rounded-3xl p-6 lg:py-14 flex md:block flex-row-reverse gap-4",
        {
          step: true,
        },
        className
      )}
    >
      <img
        src={`/images/${image}`}
        alt=""
        className="w-[56px] lg:w-[72px] max-h-[56px] lg:max-h-[82px] mx-auto mb-2 lg:mb-6"
        decoding="async"
      />
      <p className="text-lg font-medium text-font-1 text-left md:text-center">
        {body}
      </p>

      <StepIcon
        number={index + 1}
        width={173}
        className="w-[100px] lg:w-[173px] absolute left-1/2 -translate-x-1/2 -bottom-12 lg:-bottom-[72px]"
      />
    </div>
  )
}

export default Steps
