import { ContentfulPayment } from "../../types/graphql-types"

import Page from "./page"

export default class Payment {
  id: string
  slug: string
  name: string
  description: string
  page?: Page
  createdAt: Date
  imageDescription: string

  constructor(payment: ContentfulPayment) {
    this.slug = payment.slug || ""
    this.id = payment.id
    this.name = payment.name || ""
    this.description = payment.description?.description! || ""
    this.page = payment.page ? new Page(payment.page!) : undefined
    this.createdAt = new Date(payment.createdAt)
    this.imageDescription = payment.imageDescription
  }
}
